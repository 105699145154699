import React, { Component } from 'react';
import YouTube from 'react-youtube';

import css from './TalkVideo.module.scss';

class TalkVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      video: {}, 
      ready: false
    };

    this.playVideo = this.playVideo.bind(this);
  }
  
  _onReady(evt) {
    evt.target.pauseVideo();

    this.setState({
      video: evt.target, 
      ready: true
    }, (this.props.onVideoReady || (() => { })));
  }

  playVideo() {
    if (!this.state.ready) {
      return;
    }

    this.state.video.playVideo();
  }

  render() {
    const { videoId } = this.props;

    return (
      <YouTube 
        videoId={videoId}
        opts={{
          width: '100%',
          height: '100%'
        }}
        onReady={this._onReady.bind(this)}
      />
    )
  }
}

/**
 * expose
 */

export default TalkVideo;
