import React from 'react';
import { Link } from 'gatsby';

import api from '../services/api';

import { Container, Row, Col } from './react-grid';
import Pane from './Pane';
import Button from './Button';
import css from './Footer.module.scss';
import wayLogo from '../images/waye-logo-white-large.png';
import iconInstagram from '../images/icon-instagram.svg';
import iconFacebook from '../images/icon-facebook.svg';
import iconTwitter from '../images/icon-twitter.svg';
import iconYoutube from '../images/icon-youtube.svg';

const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ''
    };
  }

  handleEmailChange(evt) {
    const { name, value } = evt.target;

    this.setState({[name]: value});
  }

  handleJoinMailinglist(evt) {
    evt.preventDefault();

    if (!this.validEmail()) {
      return;
    }

    const { email } = this.state;

    fetch(`${api.host}/mailinglists`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        email: email
      })
    })
      .then(response => response.json())
      .then((data) => {
        if (data.statusCode > 400) {
          // TODO handle with better error message and UI
          alert('We ran into an problem processing your request. ' +
            'Please check your email and try again.');
        } else {
          this.setState({email: ''});
        }
      })
      .catch((err) => {
        console.log('[error]', err); // TODO handle
      });
  }

  validEmail() {
    const { email } = this.state;
    return emailReg.test(String(email).toLowerCase());
  }

  render() {
    return (
      <Pane name="footer">
        <footer className={css.footer}>
          <Container>
            <Row className={css.footer__navRow}>
              <Col sm="2" sm-offset="1" className={css.footer__logo}>
                <Link to="/"><img src={wayLogo} alt="WAYE" /></Link>
              </Col>
              <Col sm="3" className={css.footer__nav}>
                <ul className={css.nav}>
                  <li>
                    <Link to="/waye-talks">Talks</Link>
                  </li>
                  <li>
                    <Link to="/community">Community</Link>
                  </li>
                  <li>
                    <Link to="/tools-and-resources">Tools &amp; Resources</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </Col>
              <Col sm="5" className={css.footer__newsletter}>
                <div className={css.newsletter}>
                  <form action="#" onSubmit={this.handleJoinMailinglist.bind(this)}>
                    <h2>Stay in the know on all things WAYE!</h2>
                    <Row relative className={css.newsletter__row}>
                      <Col sm="3" className={css.newsletter__email}>
                        <input type="email" 
                          name="email"
                          value={this.state.email} 
                          onChange={this.handleEmailChange.bind(this)}
                          placeholder="Your email" />
                      </Col>
                      <Col sm="2" className={css.newsletter__submit}>
                        <Button submit className={[this.validEmail() ? '' : css.buttonDisabled, css.button]}>Sign Up</Button>
                      </Col>
                    </Row>
                  </form>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="3" sm-offset="3">
                <ul className={css.socialLinks}>
                  <li>
                    <a href="https://www.instagram.com/wayetalks/"><img src={iconInstagram} alt="Instagram" /></a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/WayeOfficial/"><img src={iconFacebook} alt="Facebook" /></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/waye_wayetalks"><img src={iconTwitter} alt="Twitter" /></a>
                  </li>
                  <li>
                    <a href="https://m.youtube.com/channel/UC_XlX7tdmCgyvyrsu35eVqg"><img src={iconYoutube} alt="Youtube" /></a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </footer>
      </Pane>
    );
  }
}

/**
 * expose
 */

export default Footer;
