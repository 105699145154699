import React from 'react';
import { fm } from '../utils/fm.js';

import css from './Pane.module.scss';

let classNames = (props) => {
  let {
    name,
    className,
    heighted,
    navOffset
  } = props;
  let c = [css.pane, css[fm(`pane--${name}`)]];

  if (heighted) {
    c.push(css.paneHeighted);
  }

  if (navOffset) {
    c.push(css.paneNavOffset);
  }

  if (!!className) {
    if (!(className instanceof Array)) {
      className = [className];
    }
    className.forEach((v) => { c.push(v); });
  }

  return c.join(' ');
};

const Pane = (props) => {
  return (
    <div className={classNames(props)} style={props.style}>
      {props.children}
    </div>
  )
}

/**
 * expose
 */

export default Pane;
