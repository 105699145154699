import React, { Component } from 'react';

import css from './Pagination.module.scss';

// FIXME when not row/col wrapped it's offset by about 2px from center
class Pagination extends Component {
  page(i, evt) {
    evt.preventDefault();

    this.props.onPageHandler(i, true);
  }

  activeClass(i) {
    if (this.props.active_index === i) {
      return css.active;
    }

    return '';
  }

  render() {
    let { total } = this.props;

    return (
      <div className={css.paginationGroup}>
        <ul className={css.pagination__pages}>
          {(Array.apply(null, Array(total))).map((_, i) => (
            <li key={`pagination-page-${i}`} className={this.activeClass(i)}>
              <a href="#" data-index={i} onClick={this.page.bind(this, i)}></a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

/**
 * expose
 */

export default Pagination;
