import React, { Component } from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import moment from 'moment-timezone';
import { fm } from '../utils/fm';
import api from '../services/api';

import { Container, Row, Col } from '../components/react-grid';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Pane from '../components/Pane';
import Hero from '../components/Hero';
import Button from '../components/Button.js';
import Slideshow from '../components/Slideshow';
import OverlayBlock from '../components/OverlayBlock';
import OverlayCard from '../components/OverlayCard';
import SlideshowImage from '../components/SlideshowImage';
import { FeaturedVideo } from './index.js';

import css from './waye-talks.module.scss';
import cssOg from '../components/OverlayBlock.module.scss';
import cssBtn from '../components/Button.module.scss';
import wayeTalksLogo from '../images/waye-talks-logo-white-large.png';
import wayeTalksLogoTiny from '../images/waye-talks-logo-white-tiny.png';
import timelineDotsDecoration from '../images/timeline-dots-decoration.svg';
import slideshowImage01 from '../images/specialists-slideshow-01.jpg';
import slideshowImage02 from '../images/specialists-slideshow-02.jpg';
import slideshowImage03 from '../images/specialists-slideshow-03.jpg';
import slideshowImage04 from '../images/specialists-slideshow-04.jpg';
import slideshowImage05 from '../images/specialists-slideshow-05.jpg';
import slideshowImage06 from '../images/specialists-slideshow-06.jpg';
import heroImage from '../images/talks-hero-image.jpg';
import landingPageVideoScreenshot from '../images/landing-page-video-screenshot.jpg';

import logoBloomberg from '../images/logo-bloomberg.svg';
import logoCornell from '../images/logo-cornell.svg';
import logoNyuStern from '../images/logo-nyu-stern.png';
import logoIbm from '../images/logo-ibm.svg';
import logoToronto from '../images/logo-university-toronto.svg';
import logoUn from '../images/logo-un.svg';
import logoAion from '../images/logo-aion.png';
import logoBybabba from '../images/logo-bybabba.png';
import logoHinge from '../images/logo-hinge.png';
import logoKairos from '../images/logo-kairos.png';
import logoLittlespoon from '../images/logo-littlespoon.png';
import logoProjectTsehigh from '../images/logo-project-tsehigh.png';
import logoCelsious from '../images/logo-celsious.png';

class FeaturedUpcomingTalk extends Component {
  state = {talks: []};

  componentDidMount() {
    const query = {
      _limit: 1,
      publish: true,
      featured: true,
      isAFutureTalk: true
    };
    const qs = queryString.stringify(query);

    fetch(`${api.host}/talks?${qs}`)
      .then(response => response.json())
      .then((data) => {
        this.setState({talks: data});
      })
      .catch((err) => {
        console.log('[error]', err); // TODO handle
      });
  }

  render() {
    const { talks } = this.state;

    return (
      <Row className={css.talksUpcoming__featured}>
        <Col sm="10" sm-offset="1" className={css.featured}>
          {talks.map(({ id, slug, title, excerpt, coverPhoto }, i) => (
          <Row relative className={css.featured__block}>
            <Col sm="6" className={css[fm('featured__screenShot')]} style={{
              backgroundImage: `url(${coverPhoto.url})`
            }} />
            <Col sm="4" className={css[fm('featured__info')]}>
              <div className={css[fm('featured__infoContent')]}>
                <h1>{title}</h1>
                <p>{excerpt}</p>
                <Button 
                  to={`/waye-talks/${slug || ('Talk_' + id)}`}
                  className={css.buttonLearnMore}>

                  Learn More
                </Button>
              </div>
            </Col>
          </Row>
          ))}
        </Col>
      </Row>
    );
  }
}

class UpcomingTalks extends Component {
  state = {talks: []}

  componentDidMount() {
    const query = {
      _limit: 3,
      _sort: 'date',
      publish: true,
      isAFutureTalk: true
    };
    const qs = queryString.stringify(query);

    fetch(`${api.host}/talks?${qs}`)
      .then(response => response.json())
      .then((data) => {
        this.setState({talks: data});
      })
      .catch((err) => {
        console.log('[error]', err); // TODO handle
      });
  }

  render() {
    const { talks } = this.state;

    return (
      <Col xs="12" sm="12" className={css.talksList}>
        <Row>
          {talks.map(({ id, slug, title, coverPhoto }, i) => (
          <Col xs="12" sm="4" className={css.talk}>
            <Link to={`/waye-talks/Talk_${id}`} className={css.talk__link}>
              <div className={css.talk__screenshot} style={{
                backgroundImage: `url(${coverPhoto.url})`
              }}>
                <Button 
                  to={`/waye-talks/${slug || ('Talk_' + id)}`}
                  className={css.buttonLearnMore}>

                  Learn More
                </Button>
              </div>
              <h1>{title}</h1>
            </Link>
          </Col>
          ))}
        </Row>
      </Col>
    );
  }
}

class PreviousTalks extends Component {
  state = {talks: []}

  componentDidMount() {
    const query = {
      _limit: 10,
      _sort: '-date',
      publish: true,
      isAFutureTalk_ne: true
    };
    const qs = queryString.stringify(query);

    fetch(`${api.host}/talks?${qs}`)
      .then(response => response.json())
      .then((data) => {
        this.setState({talks: data});
      })
      .catch((err) => {
        console.log('[error]', err); // TODO handle
      });
  }

  render() {
    const { talks } = this.state;

    return (
      <Row className={css.talks__more}>
        <Col xs="12" sm="12" className={css.talksList}>
          <Row>
            {talks.map(({ id, slug, title, date, coverPhoto }, i) => (
            <Col xs="12" sm="4" className={css.talk}>
              <Link to={`/waye-talks/Talk_${id}`} className={css.talk__link}>
                <div className={css.talk__screenshot} style={{
                  backgroundImage: `url(${coverPhoto.url})`
                }}>
                  <Button 
                    to={`/waye-talks/${slug || ('Talk_' + id)}`}
                    className={css.buttonLearnMore}>

                    Learn More
                  </Button>
                </div>
                <h1>{moment(date).tz('America/New_York').format('MMM, D YYYY')}</h1>
                <p>{title}</p>
              </Link>
            </Col>
            ))}
          </Row>
        </Col>
      </Row>
    );
  }
}

const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const COVER_NEXT_PLACEHOLDER = 'I would like WAYE to talk about';

class CoverNext extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dirty: false,
      message: '',
      email: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this);

    this.contentEditable = React.createRef();
  }

  handleChange(evt) {
    const { textContent } = evt.currentTarget;

    this.setState({
      dirty: true,
      message: textContent
    });
  }

  handleFocus(evt) {
    if (this.isSubmittable()) {
      return;
    }

    evt.currentTarget.textContent = '';
  }

  handleBlur(evt) {
    if (this.isSubmittable()) {
      return;
    }

    evt.currentTarget.textContent = COVER_NEXT_PLACEHOLDER;
  }

  handleFieldChange(evt) {
    const { name, value } = evt.target;
    const s = {[name]: value};

    if (name === 'message') {
      s['dirty'] = true;
    }

    this.setState(s);
  }

  validEmail() {
    const { email } = this.state;
    return emailReg.test(String(email).toLowerCase());
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if (!this.isSubmittable()) {
      return;
    }

    const { message, email } = this.state;

    fetch(`${api.host}/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        type: 'Cover Next',
        message: message,
        email: email
      })
    })
      .then(response => response.json())
      .then((data) => {
        if (data.statusCode > 400) {
          // TODO handle with better error message and UI
          alert('We ran into an problem processing your request. ' +
            'Please check your email and try again.');
        } else {
          alert("Thank you for submitting the topic you'd like " +
            "WAYE Talks to cover next");

          this.setState({dirty: false, message: '', email: ''}, () => {
            this.contentEditable.current.textContent = COVER_NEXT_PLACEHOLDER;
          });
        }
      })
      .catch((err) => {
        console.log('[error]', err); // TODO handle
      });
  }

  isSubmittable() {
    const { dirty, message } = this.state;
    return (dirty && !!message) && this.validEmail();
  }

  render() {
    return (
      <Pane name="cover-next" heighted>
        <Container className={css.coverNextForm}>
          <form action="#">
            <Row>
              <Col xs="12" sm="5" sm-prepend className={css.coverNextForm__blockText}>
                <div className={css.blockText__text}>
                  <h1>What topic would you like WAYE Talks to cover next?</h1>
                </div>

                <Row relative>
                  <Col xs="10" xs-offset="1" sm="5" sm-offset="0" className={css.blockText__email}>
                    <input
                      className={css.blockText__emailInput}
                      onChange={this.handleFieldChange.bind(this)}
                      type="email"
                      name="email"
                      value={this.state.email}
                      placeholder="Your Email" />
                  </Col>
                </Row>

                <Row relative>
                  <Col xs="10" xs-offset="1" sm="5" className={css.blockText__message}>
                    <textarea 
                      name="message"
                      onChange={this.handleFieldChange.bind(this)}
                      value={this.state.message}
                      placeholder={COVER_NEXT_PLACEHOLDER} />
                  </Col>
                </Row>

                <Row relative className={css.blockText__action}>
                  <div className={css.blockText__actionSpacer}></div>
                  <Col xs="10" xs-offset="1" sm="2" sm-offset="0" nogutter className={css.blockText__actionButton}>
                    <a href="#"
                      data-clickable={this.isSubmittable()}
                      onClick={this.handleSubmit.bind(this)}
                      className={`${cssBtn.button} ${css.buttonCoverNext}`}>

                      Submit
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" sm="6" sm-offset="1" className={css.contentEditable}>
                <section>
                  <p ref={this.contentEditable}
                    contentEditable="true" 
                    onFocus={this.handleFocus.bind(this)}
                    onBlur={this.handleBlur.bind(this)}
                    onInput={this.handleChange.bind(this)}>

                    {COVER_NEXT_PLACEHOLDER}
                  </p>
                </section>
                {/*<textarea name="cover_next" placeholder="I would like WAYE to talk about"></textarea>*/}
              </Col>
            </Row>
          </form>
        </Container>
      </Pane>
    );
  }
}

const WayeTalksPage = () => (
  <Layout name="waye-talks">
    <SEO title="WAYE Talks" keywords={[`WAYE`, `WAYE Talks`]} />

    <Pane name="landing">
      <Hero 
        name="waye-talks"
        logo={wayeTalksLogo}
        logo_alt='WAYE Talks'
        tagline="Let’s talk about the future."
        img_src={heroImage}
      />

      <Container className={css.bodyTextGroup}>
        <Row className={css.body}>
          <Col xs="10" xs-offset="1" sm="7" sm-offset="1" className={css.body__text}>
            <p>
              At WAYE Talks, we converse with industry experts to learn about
              the digital changes happening in our world and how they will
              impact us in our daily lives. From artificial intelligence to
              the future of work we ask the question - what should we know
              and do today, in our personal and professional lives, to better
              prepare us for tomorrow?
            </p>
          </Col>
          <Col xs="10" xs-offset="1" sm="4" sm-offset="0" className={css.body__action}>
            <Button to="/contact-us" className={css.buttonGetInvolved}>How To Get Involved</Button>
          </Col>
        </Row>
      </Container>
    </Pane>

    <Pane name="featured-specialists" heighted className={css.featuredSpecialists__pane}>
      <OverlayCard 
        className={css.featuredSpecialists__overlayCard}
        name="featured-specialists"
        h1="Featured Specialists"
        action={true}
        action_url="/contact-us"
        action_text="Become A Specialist"
      >
        A network of industry experts - world renowned authors, professors,
        futurists, thought-leaders, and notable professionals from the worlds
        of business and technology.
      </OverlayCard>

      <Slideshow name="featured-specialists">
        <SlideshowImage src={slideshowImage01} desc 
          title="Ritika Gunnar" 
          small="Vice President of Data and AI Expert Services & Learning, IBM" />

        <SlideshowImage src={slideshowImage02} desc
          title="Professor Vasant Dhar"
          small="Artificial Intelligence, Data Governance, & Data Science, NYU Stern" />

        <SlideshowImage src={slideshowImage03} desc
          title="Babba C. Rivera"
          small="Founder, CEO bybabba" />

        <SlideshowImage src={slideshowImage04} desc
          title="Ankur Jain"
          small="Founder, CEO Kairos" />

        <SlideshowImage src={slideshowImage05} desc
          title="Ketevan Tsereteli"
          small="Artificial Intelligence Editorial Engineer, Bloomberg" />

        <SlideshowImage src={slideshowImage06} desc
          className={css.featuredSpecialists__slideshowImageLisa}
          title="Lisa Barnett"
          small="Co-Founder & CMO, Little Spoon" />
      </Slideshow>
    </Pane>

    <Pane name="featured-brands" style={{"overflow-x": "hidden"}}>
      <Container className={css.featuredBrandsGroup}>
        <Row className={css.featuredBrands}>
          <Col xs="12" sm="9" className={css.featuredBrands__brands}>
            <Row relative={true} className={css.brandRow}>
              <Col xs="4" sm="3" className={[css.brand]}>
                <img src={logoBloomberg} alt="" />
              </Col>
              <Col xs="4" sm="3" className={[css.brand, css.brandIbm]}>
                <img src={logoIbm} alt="" />
              </Col>
              <Col xs="4" sm="3" className={[css.brand]}>
                <img src={logoNyuStern} alt="" />
              </Col>
            </Row>
            <Row relative={true} className={css.brandRow}>
              <Col xs="4" sm="3" className={[css.brand]}>
                <img src={logoCornell} alt="" />
              </Col>
              <Col xs="4" sm="3" className={[css.brand]}>
                <img src={logoToronto} alt="" />
              </Col>
              <Col xs="4" sm="3" className={[css.brand, css.brandUn]}>
                <img src={logoUn} alt="" />
              </Col>
            </Row>
            <Row relative={true} className={css.brandRow}>
              <Col xs="4" sm="3" className={[css.brand]}>
                <img src={logoAion} alt="" />
              </Col>
              <Col xs="4" sm="3" className={[css.brand]}>
                <img src={logoBybabba} alt="" />
              </Col>
              <Col xs="4" sm="3" className={[css.brand]}>
                <img src={logoHinge} alt="" />
              </Col>
            </Row>
            <Row relative={true} className={css.brandRow}>
              <Col xs="4" sm="3" className={[css.brand]}>
                <img src={logoKairos} alt="" />
              </Col>
              <Col xs="4" sm="3" className={[css.brand]}>
                <img src={logoLittlespoon} alt="" />
              </Col>
              <Col xs="4" sm="3" className={[css.brand]}>
                <img src={logoProjectTsehigh} alt="" />
              </Col>
            </Row>
            <Row relative={true} className={css.brandRow}>
              <Col xs="4" sm="3" className={[css.brand]}>
                &nbsp;
              </Col>
              <Col xs="4" sm="3" className={[css.brand]}>
                <img src={logoCelsious} alt="" />
              </Col>
              <Col xs="4" sm="3" className={[css.brand]}>
                &nbsp;
              </Col>
            </Row>
          </Col>
          <Col sm="3" sm-extend className={css.featuredBrands__heading}>
            <h1>Featured Companies</h1>
          </Col>
        </Row>
      </Container>
    </Pane>

    <FeaturedVideo videoId="E119-bs13cY" title="Experience WAYE Talks" thumbnail={landingPageVideoScreenshot} />

    <Pane name="upcoming-waye-talks" className={css.talksUpcoming}>
      <Container>
        <Row className={css.talksUpcoming__heading}>
          <Col xs="12" sm="12">
            <h1>Upcoming WAYE Talks</h1>
          </Col>
        </Row>
        <Row className={css.talksUpcoming__divider}>
          <Col xs="12" sm="6" className={css.divider__hr}>
            <hr />
          </Col>
        </Row>
        <Row className={css.talks__more}>
          <UpcomingTalks />
        </Row>
      </Container>
    </Pane>

    <Pane name="previous-waye-talks" className={css.talksPrevious}>
      <Container>
        <Row className={css.talksPrevious__heading}>
          <Col sm="1" className={css.timelineDotsDecoration}>
            <img src={timelineDotsDecoration} alt="" />
          </Col>
          <Col xs="12" sm="10" className={css.talksPrevious__title}>
            <h1>Previous WAYE Talks</h1>
          </Col>
        </Row>
        <PreviousTalks />
      </Container>
    </Pane>

    <CoverNext />
  </Layout>
);

/**
 * expose
 */

export default WayeTalksPage;
export { CoverNext };
