import React, { Component } from 'react';
import { fm } from '../utils/fm.js';

import { Container, Row, Col } from './react-grid';
import Button from './Button';
import css from './OverlayBlock.module.scss';

class OverlayBlock extends Component {
  blockColProps() {
    const b = this.props['block-on-mobile'];

    return this.props['align-right'] ? {
      nogutter: true,
      xs: b ? 12 : 11,
      'xs-offset': b ? 0 : 1,
      sm: 5,
      'sm-offset': 7,
      md: 5,
      'md-extend': true
    } : {
      nogutter: true,
      xs: b ? 12 : 11,
      'xs-offset': b ? 0 : 1,
      sm: 6,
      'sm-offset': 0,
      md: 5,
      'md-prepend': true
    };
  }

  textColProps() {
    return this.props['align-right'] ? {
      xs: 9,
      'xs-offset': 1,
      sm: 5,
      'sm-offset': 0,
      md: 5,
      'md-offset': 0
    } : {
      xs: 9,
      'xs-offset': 1,
      sm: 6,
      'sm-offset': 0,
      md: 5,
      'md-offset': 0
    };
  }

  actionColProps() {
    const b = this.props['block-on-mobile'];

    return this.props['align-right'] ? {
      xs: b ? 10 : 7,
      'xs-offset': b ? 1 : 4,
      sm: 3,
      'sm-offset': 7,
      md: 2
    } : {
      xs: b ? 10 : 7,
      'xs-offset': b ? 1 : 4,
      sm: 3,
      'sm-offset': 3,
      md: 2
    };
  }

  modifier() {
    let a = []

    if (this.props['align-right']) {
      a.push(css.overlayGroupRight)
    } else {
      a.push(css.overlayGroupLeft)
    }

    if (this.props['block-on-mobile']) {
      a.push(css.overlayGroupBlockOnMobile)
    }

    return a.join(' ')
  }

  identifier() {
    const { name } = this.props;
    return name ? css[fm(`overlay-group--${name}`)] : '';
  }

  render() {
    const { action, action_url, action_text, className } = this.props;

    return (
      <div className={`${css.overlay} ${this.modifier()} ${this.identifier()} ${className}`}>
        <Container>
          <Row>
            <Col {...this.blockColProps()}>
              <Row relative className={css.overlay__text}>
                <Col {...this.textColProps()} className={css.overlay__textCol}>
                  <h1>{this.props.children}</h1>
                </Col>
              </Row>
            </Col>
          </Row>
          {action &&
          <Row className={css.overlay__action}>
            <Col {...this.actionColProps()}>
              <Button 
                className={css.button} 
                href={action_url}>{action_text}</Button>
            </Col>
          </Row>
          }
        </Container>
      </div>
    );
  }
}

/**
 * expose
 */

export default OverlayBlock;
