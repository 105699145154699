import React, { Component } from 'react';
import { Link } from 'gatsby';

import { Container, Row, Col } from './react-grid';
import css from './Nav.module.scss';
import logo from '../images/waye-logo-black.png';
import menu from '../images/icon-hamburger.svg';

/**
 * Nav is the navigation component
 */

class Nav extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showMenu: false
    }

    this.toggleMenu = this.toggleMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  toggleMenu(evt) {
    evt.preventDefault()

    const { showMenu } = this.state

    this.setState({showMenu: !showMenu})
  }

  closeMenu(evt) {
    this.setState({showMenu: false})
  }

  render() {
    return (
      <>
        <div id="nav">
          <Container className={css.nav}>
            <Row className={css.nav__row}>
              <Col sm="2" sm-offset="1" className={css.nav__link}>
                <Link to="/waye-talks">TALKS</Link>
              </Col>
              <Col sm="2" className={`${css.nav__link} ${css.nav__linkCommunity}`}>
                <Link to="/community">COMMUNITY</Link>
              </Col>
              <Col sm="2" className={`${css.nav__link} ${css.nav__linkLogo}`}>
                <Link to="/"><img src={logo} alt="WAYE" /></Link>
              </Col>
              <Col sm="2" className={`${css.nav__link} ${css.nav__linkTools}`}>
                <Link to="/tools-and-resources">TOOLS &amp; RESOURCES</Link>
              </Col>
              <Col sm="2" className={`${css.nav__link} ${css.nav__linkAboutUs}`}>
                <Link to="/about-us">ABOUT US</Link>
              </Col>
              <Col sm="2" className={`${css.nav__link} ${css.nav__linkMenu}`}>
                <a href="#" onClick={this.toggleMenu}>
                  <img src={menu} alt="" />
                </a>
              </Col>
            </Row>
          </Container>
        </div>

        {this.state.showMenu && 
        <div className={css.menuMobile}>
          <a href="#" 
            onClick={this.toggleMenu}
            className={css.menuMobile__close}>&times;</a>

          <Container className={css.nav}>
            <Row className={css.nav__row}>
              <Col sm="12" className={[css.nav__link, css.nav__linkLogo]}>
                <Link to="/"
                  onClick={this.closeMenu}><img src={logo} alt="WAYE" /></Link>
              </Col>
              <Col sm="12" className={css.nav__link}>
                <Link to="/waye-talks"
                  onClick={this.closeMenu}>TALKS</Link>
              </Col>
              <Col sm="12" className={`${css.nav__link} ${css.nav__linkCommunity}`}>
                <Link to="/community"
                  onClick={this.closeMenu}>COMMUNITY</Link>
              </Col>
              <Col sm="12" className={`${css.nav__link} ${css.nav__linkTools}`}>
                <Link to="/tools-and-resources"
                  onClick={this.closeMenu}>TOOLS &amp; RESOURCES</Link>
              </Col>
              <Col sm="12" className={`${css.nav__link} ${css.nav__linkAboutUs}`}>
                <Link to="/about-us"
                  onClick={this.closeMenu}>ABOUT US</Link>
              </Col>
              <Col sm="12" className={`${css.nav__link} ${css.nav__linkContactUs}`}>
                <Link to="/contact-us"
                  onClick={this.closeMenu}>CONTACT US</Link>
              </Col>
            </Row>
          </Container>
        </div>
        }
      </>
    )
  }
}

/**
 * expose
 */

export default Nav;
