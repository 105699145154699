import React, { Component } from 'react';
import marked from 'marked';
import YouTube from 'react-youtube';

import { Container, Row, Col } from '../components/react-grid';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Pane from '../components/Pane';
import Hero from '../components/Hero';
import Button from '../components/Button';
import TalkVideo from '../components/TalkVideo';
import Slideshow from '../components/Slideshow';
import SlideshowImage from '../components/SlideshowImage';
import { CoverNext } from './waye-talks';

import css from './waye-talks-single.module.scss';
import wayeTalksLogo from '../images/waye-talks-logo-white-small.png';
import iconPlay from '../images/ic_play_circle_filled_24px.svg';
import iconCaretDown from '../images/ic_expand_more_24px-down.svg';
import ql from '../images/quote-mark-left.svg';
import qr from '../images/quote-mark-right.svg';

const RecapVideo = (props) => (
  <div className={css.recapVideo}>
    <div className={css.recapVideo__video}>
      <YouTube 
        videoId={props.videoId}
        opts={{
          width: '100%',
          height: '100%'
        }}
      />
    </div>
  </div>
);

class WayeTalksSingle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoReady: false,
      playVideo: false
    };

    this.video = React.createRef();
  }

  componentDidMount() {
    // scroll to video so nav is above the viewport
    setTimeout(() => {
      const nav = document.getElementById('nav');

      window.scrollTo(0, nav.offsetHeight);
    }, 100);
  }

  // setVideoReady is a callback notify this component that the video is ready
  // and playable
  setVideoReady() {
    this.setState({videoReady: true});
  }

  // playVideo calls the child compoent to play the video
  playVideo(evt) {
    evt.preventDefault();

    this.setState({playVideo: true}, this.video.current.playVideo);
  }

  isUpcoming() {
    const { strapiTalk: { date } } = this.props.data;
    return Date.parse(date) > (new Date()).getTime();
  }

  render() {
    const { data } = this.props;

    return (
      <Layout name="waye-talks-single">
        <SEO title="A Future With Artificial Intelligence" keywords={[`WAYE`, `WAYE Talks`]} />

        <Pane name="landing" heighted className={css.videoGroup}>
          {!!data.strapiTalk.youtubeVideoId &&
            <>
            {!this.state.playVideo &&
            <Container className={css.videoOverlay}>
              <Row className={css.videoOverlay__controlsGroup}>
                <Col xs="12" sm="2" className={[css.controls__decoration, css.controls__decorationRight]}>
                  <hr />
                </Col>
                <Col xs="10" xs-offset="1" sm="1" sm-offset="0" className={css.controls__logo}>
                  <img src={wayeTalksLogo} alt="WAYE Talks" />
                </Col>
                <Col xs="12" sm="2" className={[css.controls__decoration, css.controls__decorationLeft]}>
                  <hr />
                </Col>
                <Col xs="12" sm="2" className={css.controls__playAction}>
                  <a href="#" 
                    onClick={this.playVideo.bind(this)} 
                    data-video-ready={this.state.videoReady}>

                    <img src={iconPlay} alt="Play" />
                  </a>
                </Col>
              </Row>
              <Row className={css.videoOverlay__title}>
                <Col xs="10" xs-offset="1" sm="9" sm-offset="2" className={css.videoOverlay__titleCol}>
                  <h1>{data.strapiTalk.title}</h1>
                </Col>
              </Row>
              <Row className={css.videoOverlay__downCaret}>
                <Col xs="10" xs-offset="1" sm="2" sm-offset="5">
                  <img src={iconCaretDown} alt="" />
                </Col>
              </Row>
            </Container>
            }
            </>
          }
          {!this.state.playVideo &&
          <div className={css.video__screenshot} style={{
            backgroundImage: `url(${data.strapiTalk.coverPhoto.url})`
          }}></div>
          }
          {!!data.strapiTalk.youtubeVideoId &&
          <div className={css.video}>
            <TalkVideo videoId={data.strapiTalk.youtubeVideoId} 
              onVideoReady={this.setVideoReady.bind(this)}
              ref={this.video} />
          </div>
          }
        </Pane>

        <Pane name="content-body">
          <article className={css.contentBody}>
            <Container className={css.talk}>
              <Row>
                <Col xs="12" sm="4" className={css.content__details}>
                  <h1>{data.strapiTalk.title}</h1>
                  <div className={css.talk__dateOf}>{data.strapiTalk.date}</div>

                  {this.isUpcoming() &&
                  <Row relative={true} className={css.talk__joinUs}>
                    <Col xs="12" sm="2">
                      <Button href={data.strapiTalk.eventLink}>Join Us</Button>
                    </Col>
                  </Row>
                  }

                  <dl>
                    <dt>Speakers:</dt>
                    <dd>{data.strapiTalk.speakers}</dd>

                    <dt>Location:</dt>
                    <dd>{data.strapiTalk.location}</dd>
                  </dl>
                </Col>
                <Col xs="10" xs-offset="1" sm="7" sm-offset="1" className={css.talk__body}>
                  <div dangerouslySetInnerHTML={{__html: marked(data.strapiTalk.body)}}></div>
                </Col>
              </Row>
              {/**<Row>
                <Col sm="8" sm-offset="2" className={css.talk__body}>
                  <blockquote>
                    <img className={css.ql} src={ql} alt="" />
                    <p>
                      In ornare vitae massa et vehicula. Mauris lobortis mauris eget
                      tincidunt convallis. Nulla ornare condimentum ipsum, a
                      venenatis velit tincidunt in. Quisque hendrerit dignissim
                      ullamcorper.
                    </p>
                    <img className={css.qr} src={qr} alt="" />
                  </blockquote>
                  <p>
                    Duis euismod velit sed urna hendrerit, sed pellentesque nibh
                    tincidunt. Fusce elementum augue ut nunc laoreet dignissim.
                    Maecenas aliquet varius leo eu aliquam. Curabitur feugiat,
                    lacus et pharetra eleifend, nisi augue ultricies urna, sed
                    euismod turpis ex ac augue. 
                  </p>
                  <p>
                    Phasellus accumsan hendrerit metus. Aenean in metus dolor.
                    Suspendisse consequat, odio id vestibulum blandit, neque ipsum
                    ullamcorper sapien, id convallis lacus mi ut quam. Sed eu ante
                    feugiat, egestas libero vel, semper augue. Suspendisse vitae
                    risus pellentesque, placerat sem vel, imperdiet lacus. Mauris
                    vitae gravida ligula, malesuada tristique nisl.  Maecenas
                    euismod consequat felis et porttitor.
                  </p>
                  <p>
                    Quisque auctor lacus vel ipsum eleifend, ut pharetra elit
                    dignissim. Ut scelerisque dolor volutpat, hendrerit sapien nec,
                    interdum erat. 
                  </p>
                  <p>
                    Ut a fringilla nunc. Nulla facilisi. Nullam rutrum nunc a
                    tortor porta elementum.
                  </p>
                </Col>
              </Row>**/}
              <Row className={css.talk__joinUs}>
                <Col xs="12" sm="4" sm-offset="4">
                  <Button>Join Us</Button>
                </Col>
              </Row>
            </Container>
          </article>
        </Pane>

        {!!data.strapiTalk.youtubeVideoIds &&
        <Pane name="recap-videos" className={css.paneRecapVideos}>
          <Container className={css.recapVideos}>
            <Row>
              {data.strapiTalk.youtubeVideoIds.split("\n").map((id) => (
                <Col xs="12" sm="4" nogutter>
                  <RecapVideo videoId={id} />
                </Col>
              ))}
            </Row>
          </Container>
        </Pane>
        }

        {!!data.strapiTalk.photos.length &&
        <Pane name="slideshow" heighted>
          <Slideshow name="waye-talks-single">
            {data.strapiTalk.photos.map((p) => (
            <SlideshowImage src={p.url} />
            ))}
          </Slideshow>
        </Pane>
        }

        {!this.isUpcoming() &&
        <CoverNext />
        }

        {!!data.strapiTalk.photos.length &&
        <div className={css.pullFooterUp}></div>
        }
      </Layout>
    );
  }
}

/**
 * expose
 */

export default WayeTalksSingle;

export const query = graphql`
  query WayeTalksSingle($id: String) {
    strapiTalk(id: {eq: $id}) {
      title
      date(formatString: "MMM, D YYYY")
      excerpt
      coverPhoto {
        url
      }
      youtubeVideoId
      youtubeVideoIds
      speakers
      location
      eventLink
      body
      featured
      updatedAt
      photos {
        url
      }
      slug
    }
  }
`
