import React, { Component } from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import api from '../services/api';
import { capitalize } from '../utils/capitalize.js';

import { Container, Row, Col } from '../components/react-grid';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Pane from '../components/Pane';
import Hero from '../components/Hero';
import OverlayBlock from '../components/OverlayBlock';
import Slideshow from '../components/Slideshow';
import SlideshowImage from '../components/SlideshowImage';
import TalkVideo from '../components/TalkVideo';
import Button from '../components/Button';

import css from './index.module.scss';
import wayeTalksLogo from '../images/waye-talks-logo-white.png';
import slideshowImage01 from '../images/home-slideshow-01.jpg';
import slideshowImage02 from '../images/home-slideshow-02.jpg';
import slideshowImage03 from '../images/home-slideshow-03.jpg';
import heroImage from '../images/home-hero-image.jpg';
import iconCaretDown from '../images/ic_expand_more_24px-down.svg';
import iconPlay from '../images/ic_play_circle_filled_24px.svg';
import wayeTalksLogoLarge from '../images/waye-talks-logo-white-large.png';
import landingPageVideoScreenshot from '../images/landing-page-video-screenshot.jpg';
import iconTimes from '../images/icon-times.svg';
import logoWayeBlack from '../images/waye-logo-black-2.png';
import logoWayeWhite from '../images/waye-logo-white-medium.png';

import Pagination from '../components/Pagination.js';
import logoBloomberg from '../images/logo-bloomberg.png';
import logoBlockchain from '../images/logo-blockchain.png';
import logoUN from '../images/logo-un.png';
import logoDepartures from '../images/logo-departures.png';
import logoTimeout from '../images/logo-timeout.png';
import logoUsCoc from '../images/logo-us-coc.png';
import logoAfrotech from '../images/logo-afrotech.png';
import logoCoveteur from '../images/logo-coveteur.png';
import logoWired from '../images/logo-wired.png';
import logoFashonista from '../images/logo-fashonista.png';
import logoLazaridisss from '../images/logo-lazaridis-ss.png';
import logoTedRogersss from '../images/logo-ted-rogers-ss.png';
import logoTheCut from '../images/logo-the-cut.png';
import logoGoop from '../images/logo-goop.png';
import ql from '../images/quote-mark-left.svg';
import qr from '../images/quote-mark-right.svg';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    let { active } = this.props;

    let v = localStorage.getItem('mailinglistmodal');
    let d = Date.parse(v);

    if (v == 'true') {
      active = false;
    } else if (!isNaN(d))  {
      const ago = new Date();
      ago.setDate(ago.getDate() - 3);

      active = (ago.getTime() > d);
    }

    this.setState({active: active});
  }

  closeModal(evt) {
    if (evt) {
      evt.preventDefault();
    }

    // set date of modal close
    localStorage.setItem('mailinglistmodal', new Date());

    this.setState({active: false});
  }

  render() {
    const { children } = this.props;

    if (!this.state.active) {
      return (<></>);
    }

    return (
      <div className={css.modal}>
        <div className={css.modal__container}>
          <div className={css.modal__header}>
            <a href="#" onClick={this.closeModal.bind(this)}>
              <img src={iconTimes} alt="" />
            </a>
          </div>
          <div className={css.modal__content}>{children}</div>
        </div>
        <div className={css.modal__backdrop}></div>
      </div>
    );
  }
}

class ApprovedBusinessTools extends Component {
  constructor(props) {
    super(props);
    this.state = {docs: []};
  }

  componentDidMount() {
    const query = {
      _limit: 3,
      _sort: '-createdAt',
      publish: true,
      type: 'Approved Business Tool'
    };

    const qs = queryString.stringify(query);

    fetch(`${api.host}/resources?${qs}`)
      .then(response => response.json())
      .then((data) => {
        this.setState({docs: data});
      })
      .catch((err) => {
        console.log('[error]', err); // TODO handle
      });
  }

  render() {
    const { docs } = this.state;

    return (
      <>
        {docs.map(({ id, slug, title, excerpt }, i) => (
        <article key={i} className={css.article}>
          <Link to={`/community/${slug || ('Resource_' + id)}`}>
            <h1>
              {title}
            </h1>
            <p>
              {excerpt}
            </p>
          </Link>
          <hr />
        </article>
        ))}
      </>
    )
  }
}

class FeaturedVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoReady: false,
      playVideo: false
    };

    this.video = React.createRef();
  }

  // setVideoReady is a callback notify this component that the video is ready
  // and playable
  setVideoReady() {
    this.setState({videoReady: true});
  }

  // playVideo calls the child compoent to play the video
  playVideo(evt) {
    evt.preventDefault();

    this.setState({playVideo: true}, this.video.current.playVideo);
  }

  render() {
    return (
      <Pane name="landing-video" className={css.videoGroup} heighted>
        {!this.state.playVideo &&
        <div className={css.videoGroup__colorDiff}>
          <Container className={css.videoOverlay}>
            <Row className={css.videoOverlay__controlsGroup}>
              <Col sm="2" sm-offset="5" className={css.controls__playAction}>
                <a href="#" 
                  onClick={this.playVideo.bind(this)} 
                  data-video-ready={this.state.videoReady}>

                  <img src={iconPlay} alt="Play" />
                </a>
              </Col>
            </Row>
            <Row className={css.videoOverlay__title}>
              <Col sm="9" sm-offset="2" className={css.videoOverlay__titleCol}>
                <h1>{this.props.title}</h1>
              </Col>
            </Row>
            <Row className={css.videoOverlay__downCaret}>
              <Col sm="2" sm-offset="5">
                <img src={iconCaretDown} alt="" />
              </Col>
            </Row>
          </Container>
          <div className={css.video__screenshot} style={{
            backgroundImage: `url(${this.props.thumbnail})`
          }}></div>
        </div>
        }
        <div className={css.video}>
          <TalkVideo videoId={this.props.videoId} 
            onVideoReady={this.setVideoReady.bind(this)}
            ref={this.video} />
        </div>
      </Pane>
    );
  }
}

const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class StayInTheKnow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      active: true
    };

    this.modal = React.createRef();
  }

  // FIXME Reference error with webpack
  // componentWillMount() {
  //   const stayInTheKnowModal = windowGlobal.localStorage.getItem('stayInTheKnowModal');
  //   this.setState({active: !stayInTheKnowModal});
  // }

  handleEmailChange(evt) {
    const { name, value } = evt.target;

    this.setState({[name]: value});
  }

  handleJoinMailinglist(evt) {
    evt.preventDefault();

    if (!this.validEmail()) {
      return;
    }

    const { email } = this.state;

    fetch(`${api.host}/mailinglists`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        email: email
      })
    })
      .then(response => response.json())
      .then((data) => {
        if (data.statusCode > 400) {
          // TODO handle with better error message and UI
          alert('We ran into an problem processing your request. ' +
            'Please check your email and try again.');
        } else {
          this.setState({email: '', active: false}, () => {
            localStorage.setItem('mailinglistmodal', true);

            this.closeModal();
          });
        }
      })
      .catch((err) => {
        console.log('[error]', err); // TODO handle
      });
  }

  validEmail() {
    const { email } = this.state;
    return emailReg.test(String(email).toLowerCase());
  }

  closeModal(evt) {
    if (evt) {
      evt.preventDefault();
    }

    this.setState({active: false}, () => {
      // FIXME reference error in webpack
      // windowGlobal.localStorage.setItem('stayInTheKnowModal', true);

      this.modal.current.closeModal();
    });
  }

  render() {
    return (
      <Modal ref={this.modal} active={this.state.active}>
        <div className={css.stayInTheKnow}>
          <h1>
            <img src={logoWayeBlack} alt="WAYE" />
          </h1>
          <h2>Stay in the know on all things WAYE.</h2>
          <form action="#" onSubmit={this.handleJoinMailinglist.bind(this)}> <input type="email" 
              name="email" 
              value={this.state.email}
              onChange={this.handleEmailChange.bind(this)}
              placeholder="Your Email" />
            <div className={css.stayInTheKnow__actions}>
              <Button submit className={[css.button, (this.validEmail() ? '' : css.buttonDisabled)]}>Sign Up</Button>
              <br />
              <a href="#" onClick={this.closeModal.bind(this)}>Maybe Later</a>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

const PARTNER_LOGOS = [
  logoBloomberg,
  logoBlockchain,
  logoUN,
  logoDepartures,
  logoTimeout,
  logoUsCoc,
  logoAfrotech,
  logoCoveteur,
  logoWired,
  logoFashonista,
  logoLazaridisss,
  logoTedRogersss,
  logoTheCut,
  logoGoop
];

const FeaturedPartners = (props) => (
  <div className={`${css.featuredPartners} ${css['featuredPartners'+props.modifier]}`}>
    <Container>
      <Row>
        <Col xs="12" sm="10" sm-offset="1">
          <div className={css.featuredPartners__partners}>
            {props.logos.map((logo, i) => (
            <div key={i} className={css.featuredPartners__partner} data-i={i}>
              <img src={logo} alt="" />
            </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12"
          sm="10"
          sm-offset="1" 
          className={css.featuredPartners__heading}>

          <h1>{props.title}</h1>
        </Col>
      </Row>
    </Container>
  </div>
)

const QUOTES = [
  {
    quote: "Being able to share experiences and resources with and learn from other young entrepreneurs has been such an incredible tool to understanding how to run and grow our business. Through WAYE Talks, we've been able to extend our network of like-minded business owners, mentors and friends. An invaluable platform to anyone thinking about or in the process of venturing out on their own!",
    by: "Corinna Williams, Co-Founder of CELSIOUS"
  },
  {
    quote: "WAYE talks are the realness we need in this city and in this time. I'm currently a student at Columbia University, but it is largely because of the latest talk on AI that I'm now learning how to code and I feel immediately better prepared for the future that is coming for us all. WAYE talks teach, and they do it so well, but more importantly, they provide an amazing space for connection!",
    by: "Heide Morris, Student at Columbia University"
  },
  {
    quote: "The decades of learn first then work are over. Constantly educating yourself in an era of rapid technological advancements is a prerequisite for our generation. WAYE talks hit this nail right on its head, while offering an invaluable networking opportunity for a diversity of young entrepreneurs focused on the future. For anyone that strives to stay ahead of the curve, I couldn’t recommend these talks enough.",
    by: "Joshua Mitchell, Senior Vice President at New York City Economic Development Corporation"
  },
  {
    quote: "The unique value of WAYE Talks lies in bridging the gap between the management of business and  the creation of new value. Each session exposes the audience to the behavioural attributes and new imperatives of disruptive technologies, bringing urgency to a much necessary strategic transformation.",
    by: "Alexander Manu, Professor, Faculty of Design and Faculty of Graduate Studies at OCAD University"
  }
];

class Press extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0
    };

    this.isActive = this.isActive.bind(this);

    this.autoRotateInterval = undefined;
  }

  componentDidMount() {
    // begin an auto-rotation interval
    this.autoRotateInterval = setInterval(() => {
      if (this.state.activeIndex >= QUOTES.length - 1) {
        this.cancelAutoRotation();
        this.onPageHandler(0);     // bring back to first slide

        return;
      }

      this.onPageHandler(this.state.activeIndex + 1);
    }, 4444);
  }

  componentWillUnmount() {
    this.cancelAutoRotation(); // clear auto-rotote interval
  }

  cancelAutoRotation() {
    clearInterval(this.autoRotateInterval);
  }

  isActive(i) {
    return (this.state.activeIndex === i);
  }

  onPageHandler(i, click) {
    if (click) {
      this.cancelAutoRotation();
    }

    this.setState({activeIndex: i});
  }

  render() {
    return (
      <Pane name="landing-press">
        <div className={css.press}>
          <Container>
            <Row>
              <Col xs="10" xs-offset="1" sm="8" sm-offset="2" className={css.press__quoteGroup}>
                {QUOTES.map((q, i) => (
                <div key={i} className={css.press__quote} data-active={this.isActive(i)}>
                  <p>
                    <img className={css.ql} src={ql} alt="" />
                    <span>{q.quote}</span>
                    <img className={css.qr} src={qr} alt="" />
                    <small>-- {q.by}</small>
                  </p>
                </div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div className={css.pagination}>
                  <Pagination
                    total={QUOTES.length}
                    active_index={this.state.activeIndex}
                    onPageHandler={this.onPageHandler.bind(this)}/>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Pane>
    );
  }
}

const IndexPage = () => (
  <Layout name="index">
    <SEO title="Home" keywords={[`WAYE`, `WAYE Talks`]} />

    <StayInTheKnow />

    <Pane name="landing">
      <Link to="/waye-talks">
        <Hero 
          name="home"
          logo={logoWayeWhite}
          logo_alt='WAYE Talks'
          tagline="Preparing for the future of work and life with advanced technologies."
          img_src={heroImage}
        />
      </Link>

      <FeaturedPartners logos={PARTNER_LOGOS} title="Featured Partners &amp; Press" />
    </Pane>

    <Press />

    <Pane name="slideshow" heighted className={css.paneSlideshow}>
      <OverlayBlock
        className={css.paneSlideshow__overlayBlock}
        action={true}
        action_url="/community"
        action_text="How To Get Involved">
        Join WAYE in building a sustainable working future for the next
        generation.
      </OverlayBlock>
      <Slideshow name="landing">
        <SlideshowImage src={slideshowImage01} />
        <SlideshowImage src={slideshowImage02} className={css.paneSlideshow__imageSinead} />
        <SlideshowImage src={slideshowImage03} />
      </Slideshow>
    </Pane>

    <FeaturedVideo videoId="E119-bs13cY" title="Experience WAYE Talks" thumbnail={landingPageVideoScreenshot} />

    <Pane name="tools-resources" className={css.toolsResources}>
      <OverlayBlock
        align-right
        block-on-mobile
        name="tools-resources"
        modifier="tools-resources"
        action={true}
        action_url="/tools-and-resources"
        action_text="Explore WAYE Approved Business Tools">
        Tools and resources to prepare young entrepreneurs for the future of
        work.
      </OverlayBlock>

      <div className={css.approvedList}>
        <Container>
          <Row>
            <Col 
              xs="12"
              sm="5"
              sm-offset="1"
              className={css.approvedList__col}>

              <ApprovedBusinessTools />
            </Col>
          </Row>
        </Container>
      </div>
    </Pane>
  </Layout>
)

/**
 * expose
 */

export default IndexPage
export { FeaturedPartners };
export { Press };
export { FeaturedVideo };
export { PARTNER_LOGOS };