import React, { Component } from 'react';
import { fm } from '../utils/fm.js';

import { Container, Row, Col } from './react-grid';
import Pagination from './Pagination';
import css from './Slideshow.module.scss';
import expl from '../images/ic_expand_more_24px-left.svg';
import expr from '../images/ic_expand_more_24px-right.svg';

class Slideshow extends Component {
  constructor(props) {
    super(props);

    this.slideshow = React.createRef();
    this.state = {
      i: 0
    };

    this.changeSlide = this.changeSlide.bind(this);

    this.autoRotateInterval = undefined;
  }

  componentDidMount() {
    // begin an auto-rotation interval
    this.autoRotateInterval = setInterval(() => {
      if (this.state.i >= this.props.children.length - 1) {
        this.cancelAutoRotation();
        this.changeSlide(0);       // bring back to first slide

        return;
      }

      this.slideRight({preventDefault: function() {}});
    }, 4444);
  }

  componentWillUnmount() {
    this.cancelAutoRotation(); // clear auto-rotote interval
  }

  cancelAutoRotation() {
    clearInterval(this.autoRotateInterval);
  }

  /**
   * changeSlide changes the slide to the given index
   *
   * @param {Number} i
   * @param {Boolean} click (flag to signal a change has come from a click event)
   */

  changeSlide(i, click) {
    if (click) {
      this.cancelAutoRotation();
    }

    this.setState({i: i});
  }

  /**
   * slideLeft slides the slides left by one
   *
   * @param {Object} evt (click event)
   */

  slideLeft(evt) {
    evt.preventDefault();

    let i = this.state.i;
    if (i === 0) {
      return;
    }

    this.setState({i: i - 1});
  }

  /**
   * slideRight slides the slides right by one
   *
   * @param {Object} evt (click event)
   */

  slideRight(evt) {
    evt.preventDefault();

    let i = this.state.i;
    if (i >= this.props.children.length-1) {
      return;
    }

    this.setState({i: i + 1});
  }

  /**
   * slideOffset returns a margin offset that moves the slide left or right
   *
   * @return {Object}
   */

  slideOffset() {
    let c = this.slideshow.current || {offsetWidth: 0}

    return {
      marginLeft: ((this.state.i * c.offsetWidth) * -1)
    }
  }

  /**
   * controlsClassName appends a first or last modifier for the controls to
   * control display based on the state of i
   *
   * @param {String} name
   * @return {String}
   */

  controlsClassName(name) {
    let c = [css[name]];

    if (this.state.i === 0) {
      c.push(css[`${name}First`]);
    }
    if (this.state.i >= this.props.children.length-1) {
      c.push(css[`${name}Last`]);
    }

    return c.join(' ');
  }

  render() {
    let { name, children } = this.props;

    let modifier = '';
    if (name) {
      modifier = css[fm(`slideshow--${name}`)];
    }

    return (
      <div className={`${css.slideshow} ${modifier}`}>
        <div className={css.slideshowContainer}>
          {/*<div className={css.slideshowControls}>
            <Container>
              <div className={css.controls}>
                <div className={this.controlsClassName('controls__left')}>
                  <a href="#" onClick={this.slideLeft.bind(this)}><img src={expl} alt="<" /></a>
                </div>
                <div className={this.controlsClassName('controls__right')}>
                  <a href="#" className={css.right} onClick={this.slideRight.bind(this)}><img src={expr} alt=">" /></a>
                  <a href="#" className={css.left} onClick={this.slideLeft.bind(this)}><img src={expl} alt="<" /></a>
                </div>
              </div>
            </Container>
          </div>*/}
          <div className={css.pagination}>
            <Pagination total={children.length} onPageHandler={this.changeSlide} active_index={this.state.i} />
          </div>
          <div ref={this.slideshow} className={css.slideshowSlides}>
            <div className={css.slides} style={this.slideOffset()}>
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * expose
 */

export default Slideshow;
