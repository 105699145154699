import React, { Component } from 'react';
import { Link } from 'gatsby';

import css from './Button.module.scss';

class Button extends Component {
  classNames() {
    let c = [css.button];

    let { className } = this.props;
    if (!!className) {
      if (!(className instanceof Array)) {
        className = [className];
      }
      className.forEach((v) => {
        c.push(v);
      });
    }

    return c.join(' ');
  }

  render() {
    const { to, href, submit } = this.props;

    return (
      <>
      {to && 
      <Link 
        to={to} 
        className={this.classNames()}>{this.props.children}</Link>
      }
      {href &&
      <a 
        href={href} 
        className={this.classNames()}>{this.props.children}</a>
      }
      {submit &&
      <button type="submit"
        className={this.classNames()}>{this.props.children}</button>
      }
      </>
    );
  }
}


/**
 * expose
 */

export default Button;
