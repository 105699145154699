import React from 'react';
import { classnames } from './_utils.js';

import css from './_grid.module.scss';

const Column = (props) => {
  const { 
    xs,
    sm,
    md,
    lg,
    xl,
    nogutter
  } = props;

  const c = [css.col];

  if (xs) { c.push(css[`colXs${xs}`]); }
  if (sm) { c.push(css[`colSm${sm}`]); }
  if (md) { c.push(css[`colMd${md}`]); }
  if (lg) { c.push(css[`colLg${lg}`]); }
  if (xl) { c.push(css[`colXl${xl}`]); }

  const xse = props['xs-extend'];
  const sme = props['sm-extend'];
  const mde = props['md-extend'];
  const lge = props['lg-extend'];
  const xle = props['xl-extend'];
  if (xse) { c.push(css[`colXs${xs}Extend`]); }
  if (sme) { c.push(css[`colSm${sm}Extend`]); }
  if (mde) { c.push(css[`colMd${md}Extend`]); }
  if (lge) { c.push(css[`colLg${lg}Extend`]); }
  if (xle) { c.push(css[`colXl${xl}Extend`]); }

  const xsp = props['xs-prepend'];
  const smp = props['sm-prepend'];
  const mdp = props['md-prepend'];
  const lgp = props['lg-prepend'];
  const xlp = props['xl-prepend'];
  if (xsp) { c.push(css[`colXs${xs}Prepend`]); }
  if (smp) { c.push(css[`colSm${sm}Prepend`]); }
  if (mdp) { c.push(css[`colMd${md}Prepend`]); }
  if (lgp) { c.push(css[`colLg${lg}Prepend`]); }
  if (xlp) { c.push(css[`colXl${xl}Prepend`]); }

  const xso = props['xs-offset'];
  const smo = props['sm-offset'];
  const mdo = props['md-offset'];
  const lgo = props['lg-offset'];
  const xlo = props['xl-offset'];
  if ('undefined' != typeof xso) { c.push(css[`colXsOffset${xso}`]); }
  if ('undefined' != typeof smo) { c.push(css[`colSmOffset${smo}`]); }
  if ('undefined' != typeof mdo) { c.push(css[`colMdOffset${mdo}`]); }
  if ('undefined' != typeof lgo) { c.push(css[`colLgOffset${lgo}`]); }
  if ('undefined' != typeof xlo) { c.push(css[`colXlOffset${xlo}`]); }

  if (!!nogutter) c.push(css['colNogutter']);

  // FIXME support for size and other language to support a "default" so they
  // don't have to use something like xs when just referring to a single grid
  // layout

  return (
    <div className={classnames(props, c)} style={props.style}>
      {props.children}
    </div>
  )
}

/**
 * expose
 */

export default Column;
