import React from 'react';
import { fm } from '../utils/fm.js';

import { Container, Row, Col } from './react-grid';
import Button from '../components/Button.js';
import css from './OverlayCard.module.scss';

let classNames = (props) => {
  let {
    name,
    className
  } = props;
  let c = [css.overlayCard];

  if (!!className) {
    if (!(className instanceof Array)) {
      className = [className];
    }
    className.forEach((v) => { c.push(v); });
  }

  return c.join(' ');
};

const OverlayCard = (props) => {
  let { h1, children, action, action_is_link, action_url, action_text, name } = props;

  let modifier = css[fm(`overlay-card--default`)];
  if (name) {
    modifier = css[fm(`overlay-card--${name}`)];
  }

  let cardModifier = props['align-right'] ? css.cardRight : '';

  return (
    <div className={`${classNames(props)} ${modifier}`}>
      <div className={css.overlay__container}>
        <Row className={`${css.card} ${cardModifier}`}>
          <Col xs="12" sm="5" className={css.card__col} nogutter>
            <Row relative className={css.card__content}>
              <Col xs="12" sm="4" className={css.card__contentCol} nogutter>
                <div className={css.card__text}>
                  {h1 && <h1>{h1}</h1>}
                  {children && <p>{children}</p>}
                </div>
              </Col>
            </Row>
            {action &&
            <Row relative className={css.card__action}>
              {/*FIXME this should use an offset and not another Col */}
              <Col xs="10" xs-offset="1" sm="2" sm-offset="0" nogutter className={css.card__actionCol}>
                {action_is_link &&
                <Button href={action_url}>{action_text}</Button>
                }
                {!action_is_link &&
                <Button to={action_url}>{action_text}</Button>
                }
              </Col>
            </Row>}
          </Col>
        </Row>
      </div>
    </div>
  )
}

/**
 * expose
 */

export default OverlayCard;
