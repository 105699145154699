import React, { Component } from 'react';

import { Container, Row, Col } from '../components/react-grid';
import css from './SlideshowImage.module.scss';

class SlideshowImage extends Component {
  classNames() {

    let c = [css.slideshowImage];

    let { className } = this.props;
    if (!!className) {
      if (!(className instanceof Array)) {
        className = [className];
      }
      className.forEach((v) => {
        c.push(v);
      });
    }

    return c.join(' ');
  }

  render() {
    const { desc, title, small, src } = this.props;
    const style = {backgroundImage: `url(${src})`};
    const alignMod = desc === 'right' ? 
      css.slideshowImage__descBarRight : css.slideshowImage__descBarLeft;


    return (
      <div className={this.classNames()} style={style}>
        {!!desc && 
        <Container className={css.slideshowImage__desc}>
          <Row>
            <Col xs="12" sm="6" sm-offset="6" sm-extend className={css.slideshowImage__descBar}>
              <div className={css.slideshowImage__descContent}>
                <h1>{title}</h1>
                <small>{small}</small>
              </div>
            </Col>
          </Row>
        </Container>
        }
      </div>
    );
  }
}

/**
 * expose
 */

export default SlideshowImage;
